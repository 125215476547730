import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import dbantLogo from "../../../workAssets/dbantLogo.png";

export default class dbant extends React.Component {
  render() {
    return (
      <div id="projectTile3">
        <a
          id="projectTitle"
          target="_blank"
          href={"https://docs.google.com/presentation/d/1jpwOMsLp99-I4UX4qI9bbZ42cez8AHHGzq001WRxQRA/edit?usp=sharing"}
        >
          <img id="projectIconM" src={dbantLogo} alt="dbantLogo" />
        </a>
        <a
          id="projectTitle"
          target="_blank"
          href={"https://docs.google.com/presentation/d/1jpwOMsLp99-I4UX4qI9bbZ42cez8AHHGzq001WRxQRA/edit?usp=sharing"}
        >
          D-BANT
        </a>
        <h3 id="workDescription">
          A build-centric approach to traditional opportunity qualification. Examining how teams build and bring new products to market,
          relying on "Developer Dollars" can enable GTM teams to better qualify opportunities.
        </h3>
        <h4 id="workDetails">
          Drive Software Sales Strategy 
        </h4>
      </div>
    );
  }
}
