import React from "react";
import Plx from "react-plx";
import SarahBiffleResume from "../../workAssets/SarahBiffleResume.pdf";
import SeBiffle from "../../photoAssets/SeBiffle.jpg";

export default class WhatIDo extends React.Component {
  render() {
    return (
      <div className="multiItemMove">
        <div className="dialogBox">
          <h3> Relationship Builder, Solution Maker, Effective Communicator. </h3>
          <p>
            I am detail-oriented and big picture focused
            <br />
            Passionate about creating and delivering impactful, transformational business solutions
          </p>
          <p id="about-header">
            Strategy, Solution, Innovation.
            </p>
            <p id="list">
              + Collaborate with C-Level leadership (ranging from Fortune 500 to public sector to startups) to paint the picture of possible.
              Define, design, and validate impactful business solutions with executives and key stakeholders
              <div className="listBreak"/>
              + Inspire stakeholders to take action with the possible through hackathons, roadshows, workshops, and client facing assets
              <div className="listBreak"/>
              + Drive deal cycle by leading technical discovery and strategy sessions to define scope, requirements, and implementation
              <div className="listBreak"/>
              + Partner with Sales (from Account Executives to Front Line Managers to VPs) to "hack the territory", developing strategies, products, and tools to win business in diverse sales territories throughout the United States
              <div className="listBreak"/>
              + Cross-functional team leadership (product, engineering, and sales) advocating for and driving roadmaps to implement key client features proactively adapting to market changes
              <div className="listBreak"/>
              + Design and lead cross-functional enablement to improve team efficiency and increase industry knowledge. Tailor deliverables to economic factors and business objectives
              <div className="listBreak"/>
              + Create and present client deliverables (blogs, videos, design documents, presentations, manuals, workshops)
            <br />
          </p>
          <br />
          <div id="navagateButton">
            <a
              id ="navagateButtonText"
              href={SarahBiffleResume}
              download="SarahBiffleResume"
            >
              Download My Resume
            </a>
          </div>
        </div>
        <div className="dialogBox">
          <img src={SeBiffle} id="headShot" alt="SeB" />
          <br />
          <p>
            Graduate of:
            <br />
            + University of Denver - BS Finance and Marketing <span style={{fontStyle: 'italic'}}> cum laude</span>
            <div id="education"> Minors: Leadership Studies and Spanish </div>
            <div id="education"> Dean's List and Hornbeck Scholar </div>
            + Galvanize (six-month full-stack program)
            <br />
          </p>
          <br />
          <p>
            Open to opportunities in business strategy, solution design and implementation, product development, and consulting
            <div className="listBreak"/>
            + My next position will ideally empower me to incorporate my love for solutioning, diving into different industries, and building relationships to develop and drive innovative ideas.  <br/>
            <br/>
               I am particularly interested in solutioning for companies with social impact or climate missions.
          </p>
        </div>
      </div>
    );
  }
}
