import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import StudioIVRLogo from "../../../workAssets/StudioIVRLogo.png";

export default class StudioIVR extends React.Component {
  render() {
    return (
      <div id="projectTile3">
        <a
          id="projectTitle"
          target="_blank"
          href={"https://www.twilio.com/blog/serverless-studio-ivr-builder"}
        >
          <img id="projectIconM" src={StudioIVRLogo} alt="StudioIVRLogo" />
        </a>
        <a
          id="projectTitle"
          target="_blank"
          href={"https://www.twilio.com/blog/serverless-studio-ivr-builder"}
        >
          How to Build a Studio IVR Flow with No Coding Experience
        </a>
        <h3 id="workDescription">
          Have call routing needs? Don’t want your personal phone number on your website but also
          don’t want to carry two phones? Need low code and fast deployment? Check out our IVR Builder!
        </h3>
        <h4 id="workDetails">
          Build Challenge Winner || Complete Client Facing Asset
        </h4>
      </div>
    );
  }
}
