import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import VerifyLogo from "../../../workAssets/VerifyLogo.png";

export default class Verify extends React.Component {
  render() {
    return (
      <div id="projectTile2">
        <a
          id="projectTitle"
          target="_blank"
          href={"https://www.twilio.com/blog/explore-verify-no-coding-experience"}
        >
          <img id="projectIconM" src={VerifyLogo} alt="VerifyLogo" />
        </a>
        <a
          id="projectTitle"
          target="_blank"
          href={"https://www.twilio.com/blog/explore-verify-no-coding-experience"}
        >
          Explore Twilio Verify with No Coding Experience
        </a>
        <h3 id="workDescription">
        Unsure of the development lift to implement an OTP solution? Leverage TwiMo (a developing Twilio Demo Tool that doesn’t require any coding experience to use) to explore Twilio Verify!
        </h3>
        <h4 id="workDetails">
          Global Hackathon Winner || Complete Client Facing Asset
        </h4>
      </div>
    );
  }
}
