import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import numStratLogo from "../../../workAssets/numStratLogo.png";
import phoneNumStrat from "../../../workAssets/phoneNumStrat.mp4";

export default class numStrat extends React.Component {
  render() {
    return (
      <div id="projectTile3">
      <a id="projectTitle" href={phoneNumStrat} download="phoneNumStrat">
        <img id="projectIconM" src={numStratLogo} alt="Phone Number Strategy" />
      </a>
      <a id="projectTitle" href={phoneNumStrat} download="phoneNumStrat">
        An overview of the Strategy Behind Phone Numbers
      </a>
        <h3 id="workDescription">
          A look at how to examine your phone number selection and sending strategy when starting to use SMS as a
          communication channel.
        </h3>
        <h4 id="workDetails">
          SPIFF Winner & Sharable Client Resource
        </h4>
      </div>
    );
  }
}
