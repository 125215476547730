import React from "react";
import Plx from "react-plx";
import benni from "../../photoAssets/benni.jpg";
import biffleFam from "../../photoAssets/biffleFam.jpg";

export default class WhatImAbout extends React.Component {
  render() {
    return (
      <div className="mobileBox">
        <div className="dialogBox">
          <h2> What I'm About </h2>
          <p>
          Most people will tell you that I am rarely seen without a big grin across my face
          <br />
          <br />
          I build my life around the idea of continuous improvement. I love learning new things, whether it be a technology, craft,
          new industry, or someone's take on life - there is a whole world out there!
          <br/>
          I strive to be the best version of myself, and I want that version to be continuously evolving
          <br />
          <br/>
          I care deeply about our environment and making the world a better place for everyone through policy, business initiatives, and education
          <br />
          <br />
          When I am not crafting, climbing, carving (skiing), or cracking jokes with friends,
          you will find me hanging with my dog, Benedict, and husband, Patrick
          </p>
        </div>
        <div>
          <img src={biffleFam} id="biffFamT" alt="biffleFam" />
        </div>
      </div>
    );
  }
}
