import React from "react";
import Plx from "react-plx";
import StudioIVR from "./Projects/studioIVR";
import Dbant from "./Projects/dbant";
import NumStrat from "./Projects/numStrat";
import StartupWeekNav from "./Projects/startupWeekNav";
import FiftyTwoEighty from "./Projects/fiftyTwoEighty";
import SaveAway from "./Projects/saveAway";
import SocialCloud from "./Projects/socialCloud";
import Verify from "./Projects/verify";
import InternConnect from "./Projects/internConnect";
import BillTrends from "./Projects/billTrends";
import PicShares from "./Projects/picShare";
import BillTrack from "./Projects/billTrack";
import DPT from "./Projects/DPT";
import GoldCalendar from "./Projects/goldCalendar";
import MileHighWorkshop from "./Projects/mileHighWorkshop";

export default class MyWork extends React.Component {
  render() {
    return (
      <div>
        <h2> Projects </h2>
        <div className="projects">
          <Verify />
          <StudioIVR />
          <NumStrat />
        </div>

        <div className="projects">
          <Dbant />
          <StartupWeekNav />
          <BillTrends />
        </div>

        <div className="projects">
          <MileHighWorkshop />
          <SaveAway />
          <DPT />
        </div>

        <div className="projects">
          <BillTrack />
          <GoldCalendar />
          <InternConnect />
        </div>
      </div>
    );
  }
}
